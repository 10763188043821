import { VALUES_WITH_BINDING_TYPE } from '@components/BindTypeInputs/ValueInputFactory'

import { BindField, BindTypeField } from '@gantt/components/GanttCreateOrEdit/types'

export const prepareConfigData = (config: Record<string, any>) => {
  const data = structuredClone(config)
  function resetEmptyFields(item: Record<string, any>) {
    for (const property in item) {
      if (property === 'isNew') {
        delete item[property]
      }

      if (
        item[property]?.bindType === VALUES_WITH_BINDING_TYPE.FIELD &&
        !item[property]?.field?.pathStr
      ) {
        item[property].field = undefined
      } else if (item[property]?.bindType === VALUES_WITH_BINDING_TYPE.FIELD_ARRAY) {
        item[property].field_array = item[property]?.field_array?.filter(
          (item: BindTypeField) => item.field
        )
      } else {
        if (Array.isArray(item[property])) {
          item[property].map((item: BindField) => {
            resetEmptyFields(item)
          })
        }
      }
    }
  }

  resetEmptyFields(data)

  return data
}
