import { EntityCommandDTO } from '@types'

export const getCommandsFormattedRequest = (commands: EntityCommandDTO[]) => {
  return commands?.map(command => ({
    type: command.type?.label,
    name: command.name?.label,
  }))
}
export const getCommandsFormattedResponse = (commands: EntityCommandDTO[]) => {
  return (
    commands?.map(command => ({
      type: { id: command.type, label: command.type },
      name: { id: command.name, label: command.name },
    })) || [{ type: null, name: null }]
  )
}
