import { ReactElement, useEffect, useMemo, useState } from 'react'
import {
  FieldValues,
  Path,
  UseFieldArrayAppend,
  useFormContext,
  UseFormSetValue,
} from 'react-hook-form'
import cloneDeep from 'lodash/cloneDeep'
import { FormInput } from '@microservices/wiskey-react-components'

import { useFetchObjectByCodeQuery } from '@redux/api'

import { isObjectValueType } from '@helpers'
import { FIELD_VALUE_TYPE, GENERATOR_INPUT_TYPE } from '@constants'
import { GETObjectModel, ObjectFieldDTO } from '@types'

type EmbeddedObject = {
  objectCode: string
  valueType: FIELD_VALUE_TYPE
  refValueType: FIELD_VALUE_TYPE
  field?: string
  isPk?: boolean
  isInternalId?: boolean
}

type EmbeddedInputProps<T extends FieldValues> = {
  name: Path<T> // todo разобраться с типом
  append: UseFieldArrayAppend<EmbeddedObject, never>
  index: number
  setValue: UseFormSetValue<T>
  embeddedObjectPickerControllerName: any
  isFlatOptions?: boolean
  onChange?: (object: GETObjectModel & { code: string }) => void
  onInit?: (object: GETObjectModel & { code: string }) => void
}

export const EmbeddedInput = <T extends FieldValues>({
  name,
  append,
  index,
  setValue,
  embeddedObjectPickerControllerName,
  isFlatOptions,
  onChange,
  onInit,
}: EmbeddedInputProps<T>): ReactElement => {
  const { watch } = useFormContext()
  const [isInit, setIsInit] = useState(false)
  const { objectCode, refValueType } = watch(name) as EmbeddedObject
  const {
    data: object,
    isLoading,
    isSuccess,
  } = useFetchObjectByCodeQuery(objectCode, {
    skip: !objectCode,
  })

  useEffect(() => {
    if (isSuccess && !isInit) {
      setIsInit(true)
      onInit(object)
    }
  }, [isSuccess])

  const objectsList = watch(embeddedObjectPickerControllerName)

  const handleChangeSelect = (value: string | number) => {
    const isChangedRootElement = index !== objectsList.length - 1 || objectsList.length === 1
    const field = object?.fields.find((item: ObjectFieldDTO) => item.name === value)

    setValue(name, {
      ...watch(name),
      field: value,
      valueType: field.valueType,
      isPk: field?.isPk,
      isInternalId: field?.isInternalId,
    })

    if (isObjectValueType(field?.valueType)) {
      append({
        objectCode: field.model,
        refValueType: field.valueType,
        field: null,
      })
    }

    if (isChangedRootElement) {
      const newList = cloneDeep(objectsList)?.filter(
        (item: EmbeddedObject, i: number) => i <= index
      )

      if (isObjectValueType(field.valueType)) {
        newList.push({
          objectCode: field.model || '',
          refValueType: field.valueType,
          field: null,
        })
      }

      setValue(embeddedObjectPickerControllerName, newList)
    }

    onChange?.(object)
  }

  const selectOptions = useMemo(() => {
    let options = structuredClone(object?.fields) || []

    if (isFlatOptions) {
      options = options?.filter((item: ObjectFieldDTO) => !isObjectValueType(item.valueType)) || []
    }

    if (
      [
        FIELD_VALUE_TYPE.OBJ_EMBEDDED,
        FIELD_VALUE_TYPE.OBJ_PK_LINK,
        FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK,
      ].includes(refValueType)
    ) {
      options?.push({ id: 0, name: ' ' })
    }

    return options
  }, [object, isFlatOptions, refValueType])

  if (!objectCode) {
    return <></>
  }

  return (
    <FormInput
      inputType={GENERATOR_INPUT_TYPE.SELECT}
      label={objectCode}
      loading={isLoading}
      name={`${name}.field`}
      selectOptions={selectOptions}
      rules={{
        required: true,
        validate: value => (value.trim().length === 0 ? false : undefined),
      }}
      onChangeSelect={handleChangeSelect}
    />
  )
}
