import { FC, useContext } from 'react'
import { Box, CircularProgress, Grid } from '@mui/material'

import { useFetchObjectByCodeQuery } from '@redux/api'

import { FormContext } from '../../DisplayForm'
import { ContainerOnForm } from '../ContainerOnForm'

export const ConfiguredFormElements: FC = () => {
  const { containers, objectCode, isFetchingForm } = useContext(FormContext)
  const { isFetching: isFetchingObject } = useFetchObjectByCodeQuery(objectCode, {
    skip: !objectCode,
  })

  return (
    <Grid container>
      <Grid item zeroMinWidth xs>
        <Box>
          {isFetchingObject || isFetchingForm ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 100,
                width: '100%',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            containers.map((container, containerIndex) => (
              <ContainerOnForm key={container.id} container={container} index={containerIndex} />
            ))
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
