import { FC, HTMLAttributes } from 'react'
import { AutocompleteOption, EntityType, ObjectDataRecord, ViewRow } from 'src/types'
import {
  BIND_TYPE,
  FIELD_VALUE_FORMAT,
  FIELD_VALUE_TYPE,
  MIN_AUTOCOMPLETE_POPPER_WIDTH,
} from '@constants'
import {
  getTimeFromSeconds,
  isObjectValueType,
  renderBooleanValueCell,
  renderJSValueCell,
  renderJSXValueCell,
  renderObjectEmbeddedCell,
} from '@helpers'
import { Box, Grid } from '@mui/material'

import { MappedCheckbox } from '@components/MappedCheckbox'

export type DropDownListLineProps = {
  dropDownList: EntityType | undefined
  combinedData: ObjectDataRecord[]
  option: AutocompleteOption
  liProps: HTMLAttributes<HTMLLIElement>
  columns: ViewRow[]
  isDialogWindow: boolean
  pkFieldName?: string
}

export type DropDownListHeadersProps = Pick<DropDownListLineProps, 'columns'>

const getColHeader = (title: string) => {
  return (
    <Grid
      container
      item
      alignItems='center'
      justifyContent='flex-start'
      title={title}
      sx={{
        borderRight: `1px solid #303030`,
        flex: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        minHeight: 25,
        backgroundColor: theme => theme.palette.background.headerTable,
        maxHeight: 52,
        px: '4px',
        '&:last-child': {
          borderRight: `none`,
        },
      }}
    >
      {title}
    </Grid>
  )
}

export const DropDownListHeaders: FC<DropDownListHeadersProps> = ({ columns }) => {
  return (
    <Grid
      sx={{
        display: 'block',
        marginTop: 0,
        padding: 0,
        minWidth: MIN_AUTOCOMPLETE_POPPER_WIDTH,
      }}
    >
      <Grid
        container
        flexWrap='nowrap'
        sx={{
          m: 0,
          p: 0,
          border: `1px solid #303030`,
          borderLeft: 'none',
          borderTop: 'none',
        }}
      >
        {columns.map(column => {
          return getColHeader(column.title)
        })}
      </Grid>
    </Grid>
  )
}

export const DropDownListLine: FC<DropDownListLineProps> = ({
  option,
  dropDownList,
  combinedData,
  liProps,
  columns,
  isDialogWindow,
  pkFieldName = '_id',
}) => {
  const currentDataRowIndex = combinedData.findIndex(data => data[pkFieldName] === option.id)

  const renderValue = (column: ViewRow) => {
    const asCheckbox =
      column.valueFormat === FIELD_VALUE_FORMAT.NUMBER ||
      column.valueFormat === FIELD_VALUE_FORMAT.BOOLEAN

    if (asCheckbox && column.valueFormat) {
      return (
        <Box sx={{ mt: 0.5 }}>
          <MappedCheckbox
            viewOnly
            valueFormat={column.valueFormat}
            outerValue={
              combinedData[currentDataRowIndex][column.value] as unknown as number | boolean
            }
          />
        </Box>
      )
    }

    if (column.valueFormat === FIELD_VALUE_FORMAT.TIME) {
      return getTimeFromSeconds(combinedData[currentDataRowIndex][column.value])
    }

    const srcObj = combinedData[currentDataRowIndex] || {}

    if (column.bindType === BIND_TYPE.JSX) {
      return renderJSXValueCell(column.value, srcObj)
    }

    if (column.bindType === BIND_TYPE.JS) {
      return renderJSValueCell(column.value, srcObj)
    }

    if (isObjectValueType(column.valueType) && column.objectValue) {
      return renderObjectEmbeddedCell(column.value, column.objectValue, srcObj)
    }

    if (column.valueType === FIELD_VALUE_TYPE.BOOLEAN) {
      return renderBooleanValueCell(combinedData[currentDataRowIndex][column.value])
    }

    return combinedData[currentDataRowIndex][column.value]
  }

  return (
    <>
      {currentDataRowIndex === 0 ? <DropDownListHeaders columns={columns} /> : null}
      <li
        {...liProps}
        style={{
          display: 'block',
          marginTop: 0,
          padding: 0,
          minWidth: MIN_AUTOCOMPLETE_POPPER_WIDTH,
        }}
      >
        <Grid
          container
          flexWrap='nowrap'
          sx={{
            m: 0,
            p: 0,
            height: isDialogWindow ? '25px' : '36px',
          }}
        >
          {columns.map(column => {
            return currentDataRowIndex !== -1 ? (
              <Grid
                item
                title={combinedData[currentDataRowIndex][column.value]}
                sx={{
                  p: '0 8px',
                  borderRight: `1px solid #303030`,
                  flex: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  minHeight: 25,
                  maxHeight: 52,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {renderValue(column)}
              </Grid>
            ) : (
              <></>
            )
          })}
        </Grid>
      </li>
    </>
  )
}
