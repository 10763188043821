import { ActionData } from '@microservices/gantt-react-component'

import { getParamsStringSafe } from '@helpers'

import { AXIS_TYPE, AxisDefinedData, GanttFormValues } from './types'

type GetAxisData = Record<
  AXIS_TYPE | 'undefined',
  (actionData: ActionData, ganttConfig: GanttFormValues) => AxisDefinedData
>

export const getAxisData: GetAxisData = {
  X(actionData: ActionData, ganttConfig: GanttFormValues): AxisDefinedData {
    const timeline = ganttConfig.timeline.find(tml => tml.data?.id === actionData?.data?.objectCode)
    const key = timeline ? getParamsStringSafe(timeline?.key) : ''

    return {
      objectCode: actionData?.data?.objectCode,
      objectId: actionData?.data?.[key],
    }
  },
  Y(actionData: ActionData, ganttConfig: GanttFormValues): AxisDefinedData {
    const key = getParamsStringSafe(ganttConfig.resource.key)

    return {
      objectCode: ganttConfig?.resource?.data?.id || '',
      objectId: actionData?.data?.[key],
    }
  },
  undefined(): AxisDefinedData {
    console.warn('there is no definition axis for action')

    return {
      objectCode: '',
      objectId: '',
    }
  },
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const stopPropagation = (data: ActionData) => data?.metaData?.event?.stopPropagation?.()
