import { BOOLEAN_SELECT_OPTIONS, FIELD_VALUE_TYPE, MIN_AUTOCOMPLETE_POPPER_WIDTH } from '@constants'
import { FormInputProps } from '@microservices/wiskey-react-components'
import { FormRow } from '@types'

type GetBooleanSelectInputPropsParams = {
  dirtyFieldsObj: Partial<
    Readonly<{
      [x: string]: boolean
    }>
  >
  field: FormRow
  className: 'update-row' | undefined
  isFormDialogWindow: boolean
  booleanSelectValue: string | boolean
  handleSetOuterValueForMappedCheckbox: (value: number | boolean) => void
}

export const getBooleanSelectInputProps = ({
  dirtyFieldsObj,
  field,
  className,
  isFormDialogWindow,
  booleanSelectValue,
  handleSetOuterValueForMappedCheckbox,
}: GetBooleanSelectInputPropsParams): Pick<
  FormInputProps,
  'sx' | 'MenuProps' | 'selectOptions'
> => {
  const selectFormInputProps: Pick<
    FormInputProps,
    'sx' | 'MenuProps' | 'selectOptions' | 'value' | 'onChangeSelect'
  > = {
    selectOptions: BOOLEAN_SELECT_OPTIONS,
    value: booleanSelectValue || booleanSelectValue === false ? `${booleanSelectValue}` : '',
    onChangeSelect: value => {
      if (value === 'false') {
        handleSetOuterValueForMappedCheckbox(false)
      }

      if (value === 'true') {
        handleSetOuterValueForMappedCheckbox(true)
      }
    },
    MenuProps: {
      sx: {
        zIndex: theme => theme.zIndex.tooltip,
        '& ul': {
          p: 0,
        },
        '& li': {
          display: 'block',
          mt: 0,
          p: 0,
          minWidth: MIN_AUTOCOMPLETE_POPPER_WIDTH,
          m: 0,
          height: isFormDialogWindow ? '25px' : '36px',
          pl: 2,
        },
      },
    },
    sx: {
      '.Mui-disabled': {
        background: theme => `${theme.palette.background.fieldDisabled} !important`,
        '-webkit-text-fill-color': theme => `${theme.palette.color.field} !important`,
      },
      '.MuiTypography-root': {
        color: theme => theme.palette.color.default,
      },
      '.Mui-error': {
        background: theme => `${theme.palette.background.fieldError} !important`,
      },
      '& input:hover': {
        border: theme => theme.palette.border.field,
        background: 'red',
      },
      '.MuiSelect-select': {
        py: 0,
        pl: 2,
        '*': {
          transition: '0.2s ease-in',
        },
        color: theme => theme.palette.color.field,
        border: theme => theme.palette.border.field,
        // opacity: 0.8,
        borderRadius: 0,
        background: theme =>
          (dirtyFieldsObj && field.value && !!dirtyFieldsObj[field.value]) || Boolean(className)
            ? `${theme.palette.background.fieldUpdated} !important`
            : theme.palette.background.searchAssistantLabel,
        '& input:-webkit-autofill': {
          '-webkit-box-shadow': theme =>
            `0 0 0 100px ${theme.palette.background.fieldUpdated} inset`,
        },
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none !important',
        },
      },
      '.MuiSvgIcon-root': {
        '&.Mui-disabled': {
          background:
            field.valueType === FIELD_VALUE_TYPE.BOOLEAN ? 'transparent !important' : 'initial',
        },
      },
    },
  }

  return selectFormInputProps
}
