import { FC, useContext, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'

import { BindTypeInputs } from '@components/BindTypeInputs'

import { GENERATOR_INPUT_TYPE, REGEX } from '@constants'
import { ObjectFieldDTO } from '@types'

import {
  BIND_TYPE_INPUTS_FORM_TYPE,
  PageContext,
  SegmentFieldType,
  SegmentType,
} from '@gantt/components/GanttCreateOrEdit'

import { BaseFormFields, SegmentLabelsFields } from '../../components'

//todo actual?
type Props = {
  type: SegmentType
  objectFieldsForValidation?: ObjectFieldDTO[]
}

export const SegmentItem: FC<Props> = () => {
  const { t } = useTranslation()
  const { watch, setValue, resetField } = useFormContext()
  const [isShowScriptDialog, setIsShowScriptDialog] = useState(false)
  const { watchedObjectResource, modalType, currentBarObject, currentBarIndex } =
    useContext(PageContext)

  const watchResourceKeyField = watch(`link.resourceKeyField`)
  const watchSystemName = watch(`link.systemName`)
  const watchResourceLinkField = watch(`link.resourceLinkField`)

  useEffect(() => {
    if (watchResourceKeyField !== `sectionResource_${watchSystemName}`) {
      !watchSystemName
        ? resetField(`link.resourceKeyField`)
        : setValue(`link.resourceKeyField`, `sectionResource_${watchSystemName}`)
    }

    if (watchResourceLinkField !== `sectionLink_${watchSystemName}`) {
      !watchSystemName
        ? resetField(`link.resourceLinkField`)
        : setValue(`link.resourceLinkField`, `sectionLink_${watchSystemName}`)
    }
  }, [watchSystemName])

  const toggleOpenScriptValueDialog = () => {
    setIsShowScriptDialog(!isShowScriptDialog)
  }

  return (
    <Box sx={{ padding: 2 }}>
      <FormInput
        disabled={modalType === 'edit'}
        inputType={GENERATOR_INPUT_TYPE.INPUT}
        label={t('ganttCreate.timelineForm.systemName')}
        name={`link.systemName`}
        placeholder={t('ganttCreate.timelineForm.systemName')}
        replacePattern={REGEX.MODEL_CODE_REPLACE_PATTERN}
        rules={{
          required: true,
          validate: value => value.trim().length !== 0,
        }}
      />
      <Box component={'fieldset'} mb={1}>
        <legend>{t('ganttCreate.objectLinks')}</legend>
        <BindTypeInputs
          hasCommands
          bindTypeOptions={[]}
          blockLabel={t('ganttCreate.timelineForm.segmentAxisYKey')}
          containerName={'link.axisY'}
          formType={BIND_TYPE_INPUTS_FORM_TYPE.RESOURCE}
          isDisabled={!watchedObjectResource}
          watchedObject={watchedObjectResource}
        />
        <div style={{ width: 50 }} />
        <BindTypeInputs
          hasCommands
          bindTypeOptions={[]}
          blockLabel={t('ganttCreate.timelineForm.segmentAxisXKey')}
          containerName={'link.axisX'}
          formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
          isDisabled={!currentBarObject}
          watchedObject={currentBarObject}
        />
        <div hidden>
          <FormInput
            disabled
            readOnly
            inputType={GENERATOR_INPUT_TYPE.INPUT}
            label={t('ganttCreate.resourceField')}
            name={`link.resourceKeyField`}
          />
        </div>
      </Box>
      <BindTypeInputs
        fieldsetMarkup
        hasCommands
        bindTypeOptions={[]}
        containerName={'datetimeStart'}
        fieldsetTitle={t('ganttCreate.timelineForm.dateTimeStart')}
        formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
        isDisabled={!currentBarObject}
        prefix={`bar_${currentBarIndex}_segment_${watchSystemName}_${SegmentFieldType.DATETIME_START}`}
        watchedObject={currentBarObject}
      />
      <BindTypeInputs
        fieldsetMarkup
        hasCommands
        bindTypeOptions={[]}
        containerName={'datetimeEnd'}
        fieldsetTitle={t('ganttCreate.timelineForm.dateTimeEnd')}
        formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
        isDisabled={!currentBarObject}
        prefix={`bar_${currentBarIndex}_segment_${watchSystemName}_${SegmentFieldType.DATETIME_END}`}
        watchedObject={currentBarObject}
      />
      <BindTypeInputs
        fieldsetMarkup
        bindTypeOptions={[]}
        containerName={'duration'}
        fieldsetTitle={t('ganttCreate.timelineForm.duration')}
        formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
        isDisabled={!currentBarObject}
        watchedObject={currentBarObject}
      />
      <BaseFormFields
        currentBarKey={`bar_${currentBarIndex}`}
        disabled={!currentBarObject}
        isShowScriptDialog={isShowScriptDialog}
        keyValue={`segment_${watchSystemName}`}
        toggleOpenScriptValueDialog={toggleOpenScriptValueDialog}
        watchedObject={currentBarObject}
      />
      <SegmentLabelsFields watchedObject={currentBarObject} />
    </Box>
  )
}
