import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { Box } from '@mui/material'

import { BindTypeInputs } from '@components/BindTypeInputs'

import { AutocompleteOption } from '@types'

import { BIND_TYPE_INPUTS_FORM_TYPE } from '@gantt/components/GanttCreateOrEdit'

type SegmentLabelsFieldsProps = {
  watchedObject?: AutocompleteOption<string> | null
}

const labelHints = {
  js: i18next.t('ganttCreate.timelineForm.hint.cornersScriptHint'),
}

export const SegmentLabelsFields = ({ watchedObject }: SegmentLabelsFieldsProps) => {
  const { t } = useTranslation()
  const isDisabled = !watchedObject

  return (
    <Box component={'fieldset'}>
      <legend>{t('ganttCreate.timelineForm.segmentLabelsBlock')}</legend>
      <BindTypeInputs
        divide
        blockLabel={t('ganttCreate.timelineForm.leftTopTitle')}
        containerName={'labels.leftTopTitle'}
        formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
        hintDict={labelHints}
        isDisabled={isDisabled}
        watchedObject={watchedObject}
      />
      <BindTypeInputs
        divide
        blockLabel={t('ganttCreate.timelineForm.topTitle')}
        containerName={'labels.topTitle'}
        formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
        hintDict={labelHints}
        isDisabled={isDisabled}
        watchedObject={watchedObject}
      />
      <BindTypeInputs
        divide
        blockLabel={t('ganttCreate.timelineForm.rightTopTitle')}
        containerName={'labels.rightTopTitle'}
        formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
        hintDict={labelHints}
        isDisabled={isDisabled}
        watchedObject={watchedObject}
      />
      <BindTypeInputs
        divide
        blockLabel={t('ganttCreate.timelineForm.rightBottomTitle')}
        containerName={'labels.rightBottomTitle'}
        formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
        hintDict={labelHints}
        isDisabled={isDisabled}
        watchedObject={watchedObject}
      />
      <BindTypeInputs
        divide
        blockLabel={t('ganttCreate.timelineForm.bottomTitle')}
        containerName={'labels.bottomTitle'}
        formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
        hintDict={labelHints}
        isDisabled={isDisabled}
        watchedObject={watchedObject}
      />
      <BindTypeInputs
        blockLabel={t('ganttCreate.timelineForm.leftBottomTitle')}
        containerName={'labels.leftBottomTitle'}
        formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
        hintDict={labelHints}
        isDisabled={isDisabled}
        watchedObject={watchedObject}
      />
    </Box>
  )
}
