import isObject from 'lodash/isObject'
import { FormDataEnriched, FormElement, FormRow, ObjectFieldDTO } from 'src/types'

import { BIND_TYPE, FORM_ELEMENT_TYPE } from '@constants'

export const checkValidElementInLine = (
  fields: ObjectFieldDTO[],
  element: FormElement<FormDataEnriched>
) => {
  const isFieldElement = element.type === FORM_ELEMENT_TYPE.FIELD
  const hasValue = element.data && isObject(element.data) && 'value' in element.data
  const isElement = Object.values(FORM_ELEMENT_TYPE).includes(element.type)
  const isJSField = hasValue && (element.data as FormRow).bindType === BIND_TYPE.JS

  if (isFieldElement && !isJSField) {
    const includeValueInRow = Boolean(
      hasValue && fields.find(({ name }) => name === (element.data as FormRow)?.value)
    )

    return includeValueInRow
  }

  return isElement
}
