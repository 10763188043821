export const localizationEn = {
  logout: 'Logout',
  ND: 'N/D',
  main: 'Main',
  views: {
    flightView: 'Flight View',
    resourceView: 'Resource View',
    taskView: 'Task View',
  },
  placeholder: {
    select: 'Select a value',
    renderType: 'Select a render type',
    columnToSort: 'Select a field to sort by',
    value: 'Select a value',
    fieldValue: 'Field value',
    objectValue: 'Select an object value',
    objectFormCode: 'Select an object form internal id',
    bindType: 'Select a bind type',
    valueType: 'Select a value type',
    actionType: 'Select a value action',
    event: 'Select an event',
    selectType: 'Select a type',
    selectTypeFirst: 'Select a type first',
    selectObject: 'Select an object',
    selectCommandType: 'Select type',
    selectCommandCode: 'Select code',
    selectLCs: 'Select list controls',
    columnsIds: 'Select columns',
    direction: 'Select a direction',
    category: 'Select a category',
    title: 'Field title',
    placeholder: 'Add name',
    internalId: 'Internal id',
    order: 'Field order',
    objectsField: 'Object',
    params: 'Json params',
    refPath: 'Data Reading Path',
    dropDownList: 'Select a dropdown list',
    dropDownWindow: 'Select a dropdown window',
    DEFAULT: 'Select a view',
    GANTT_CHART: 'Select a Gantt Chart',
    MENU: 'No options',
    variable: 'Variable',
    selectCommand: 'Select a command',
  },
  label: {
    value: 'Value',
    valueType: 'Value Type',
    bindType: 'Binding',
    title: 'Title',
    placeholder: 'Placeholder',
    placeholderCheckbox: 'Default',
    internalId: 'Internal Id',
    order: 'Order',
    objectValue: 'Object Value',
    objectFormCode: 'Object Form Code',
    objectsField: 'Group of objects',
    titleHidden: 'Hide',
    userRequired: 'Mandatory',
    editField: 'Edit',
    objectTitle: 'Object',
    commandTitleWithIndex: 'Command №{{number}}',
    commandTitle: 'Command',
    commandType: 'Type',
    commandCode: 'Code',
    addCommand: 'Add Command',
    integrationTitle: 'Integration',
    json: 'JSON',
    params: 'Params',
    dropDownList: 'Dropdown list',
    dropDownWindow: 'Dropdown window',
    asCheckbox: 'As checkbox',
    asDuration: 'As duration',
  },
  error: {
    valueJS: 'Invalid syntax',
    common: 'Error',
    dynamicFormsExceeded: 'You cannot open more than {{count}} dynamic windows',
    allObjectValues: 'All object values are already used',
    mappedCheckboxInvalidValue: 'Value {{outerValue}} is invalid',
    duplicatedSystemName: 'Duplicated System Name',
    ganttParametersForm: {
      rowHeightMinMax: 'Should be greater than or equal to 20 and less than or equal to 100',
      backlogHeightMin: 'Should be greater than or equal to 50',
      yAxisWidthMin: 'Should be greater than or equal to 20',
      realtimeLineWidth:
        'Should be greater than or equal to 0.5 and less than or equal to 10, step: 0.5',
    },
    functionReturn: `Must contain 'return'`,
    variable: `There is no variable {{variable}}`,
    alreadyUsed: '{{name}} is already used!',
    functionReturnValueType: {
      arrayOfObjects: 'Returned value must be array of objects',
      string: 'Returned value must be string',
    },
    notValidReturnedType: 'Not valid returned type',
  },
  appbar: {
    title: {
      admin: 'Wiskey Admin',
      user: 'Wis-key - Department - Stg',
    },
  },
  configuredView: {
    add: 'Add',
    create: 'Create',
    edit: 'Edit',
    select: 'Select',
    filters: {
      default: 'Filter Orders',
    },
  },
  configControlPanels: {
    add: 'Add Control Panel',
  },
  configViews: {
    all: 'all',
    groups: 'Groups',
    views: 'views',
    forms: 'forms',
    notAssigned: 'Not assigned',
    add: {
      views: 'Add view',
      forms: 'Add form',
    },
    searchBtn: 'Search',
    searchPlaceholder: {
      views: 'Search schema...',
      forms: 'Search orders...',
    },
    allTab: {
      objectFilter: 'Object',
      search: 'Search',
      searchBoolean: 'Select (Boolean)',
    },
  },
  navigation: 'Navigation',
  pageTitle: {
    styleSettings: 'Style Settings',
    contexts: 'Contexts',
    controlPanels: 'Control panels',
    contextMenu: 'Context Menu',
    models: 'Models',
    modelsControl: 'UI Models Control',
    dictionary: 'Dictionary Values',
    settings: 'Settings',
    menuSetting: 'Menu',
    viewsList: 'Views List',
    ganttModels: 'Gantt Models',
    ganttModelsControl: 'UI Gantt Models Control',
    variables: 'Variable Values',
  },
  configGantt: {
    add: 'Add Gantt',
    ganttList: {
      id: 'ID',
      title: 'Title',
      resourceObjectCode: 'Axis Y Object',
      timelineObjectCode: 'Axis X Object',
    },
  },
  controlPanelCreate: {
    title: 'New/Edit Control Panel',
    form: {
      code: {
        label: 'Code',
        placeholder: 'Code',
      },
      title: {
        label: 'Control Panel Title',
        placeholder: 'Control Panel Title',
      },
      lineTitle: {
        label: 'Line Title',
        placeholder: 'Line Title',
      },
      addLineButton: 'Add line',
      removeLineButton: 'Delete line',
      cancelButton: 'Cancel',
      saveButton: 'Save',
      tabs: {
        buttons: 'Buttons',
        icons: 'Icons',
      },
      addItem: {
        pointType: {
          placeholder: 'Select Menu Point Type',
        },
        name: {
          placeholder: 'Name item',
        },
        entityType: {
          placeholder: 'Select Object Type',
        },
        entity: {
          placeholder: 'Select Object',
        },
        showTitle: 'Show Title',
      },
    },
  },
  ganttCreate: {
    editModel: 'Edit Gantt',
    newModel: 'New Gantt',
    refEdit: 'Edit',
    refPick: 'Pick',
    timelinesAxis: 'Axis X',
    resourceAxis: 'Axis Y',
    objectLinks: 'Link',
    resourceField: 'Resource Field',
    timelinesObjectTooltips: 'Tooltip',
    timelinesObjectTitles: 'Title',
    modalPathPickerObjectsColumn: 'Objects',
    modalPathPickerFieldsColumn: 'Fields',
    hasHeader: 'Header',
    hasFooter: 'Footer',
    hasBacklog: 'Backlog',
    generalForm: {
      timeVisualizationSection: 'Time visualization',
      step: 'General',
      tabs: {
        general: 'General',
        axisX: 'Axis X',
        axisY: 'Axis Y',
      },
      labels: {
        rowHeight: 'Row Height',
        hasBacklog: 'Backlog',
        backlogHeight: 'Backlog Height',
        yAxisWidth: 'Y Axis Width',
        xAxisWidth: 'X Axis Width',
        noRealTime: 'No Real Time',
        hasRealtimeLine: 'Realtime Line',
        realtimeLineWidth: 'Line Width',
        realtimeLineColor: 'Line Color',
        pastBackgroundColor: 'Past Background',
        futureBackgroundColor: 'Future Background',
        unitInterval: 'Unit Interval',
        hasToolbar: 'Toolbar',
        extraVirtualizationCells: 'Extra Virtualization Cells',
        extraVirtualizationRows: 'Extra Virtualization Rows',
        sortable: 'Sortable',
      },
      placeholders: {
        rowHeight: 'Enter Row Height',
        backlogHeight: 'Enter Backlog Height',
        yAxisWidth: 'Enter Y Axis Width',
        xAxisWidth: 'Enter X Axis Width',
        realtimeLineWidth: 'Enter Realtime Line Width',
        unitInterval: 'Enter Unit Interval',
        extraVirtualizationCells: 'Extra Virtualization Cells',
        extraVirtualizationRows: 'Extra Virtualization Rows',
      },
      params: 'Parameters',
    },
    timelineForm: {
      step: 'Axis X',
      fields: 'fields',
      actions: 'actions',
      dateTimeEnd: 'Date End',
      dateTimeStart: 'Date Start',
      segmentType: 'Type',
      addSegment: 'Add segment',
      key: 'Axis X Key',
      background: 'Background',
      systemName: 'System Name',
      additionalFieldsForm: {
        noFieldItems: 'There are no fields',
        tabName: 'Variables',
        variableHeader: 'Variable',
        pathHeader: 'Path',
        availableVariables: 'Available variables',
        copyAlert: 'Copied to clipboard',
      },
      hasCorners: 'Corners',
      generalTitle: 'Title',
      generalTooltip: 'Tooltip',
      duration: 'Duration',
      title: 'Title',
      segmentDialogCreate: 'Create',
      segmentDialogEdit: 'Edit',
      segmentLabelsBlock: 'Labels',
      titleBindingType: 'Title Binding',
      leftTopTitle: 'Left Top Title',
      topTitle: 'Top Title',
      rightTopTitle: 'Right Top Title',
      rightBottomTitle: 'Right Bottom Title',
      bottomTitle: 'Bottom Title',
      leftBottomTitle: 'Left Bottom Title',
      sortBindingType: 'Sort Binding',
      sort: 'Default Sort Data',
      filter: 'Default Filter Data',
      segmentAxisXKey: 'AxisX',
      segmentAxisYKey: 'AxisY',
      barName: `Bar {{index}} | {{name}} `,
      addBar: 'Add Bar',
      hint: {
        titleScriptHint:
          'Enter a function body that returns a string. Available input parameters: mainData, restData, metaData or use array "arguments"',
        tooltipScriptHint:
          'Enter a function body that returns a string. Available input parameters: mainData, restData, metaData or use array "arguments"',
        sortScriptHint: `Expected the function that returns the sorting criterion`,
        sortTextHint: `Expected format: [ { "field":"name","order":"asc" } ]`,
        backgroundColorScriptHint: `Enter a function body that returns a string that matches the color code. Available input parameters: mainData, restData, metaData or use array "arguments"`,
        backgroundColorStaticHint: `Enter a string that matches the color code.`,
        cornersScriptHint: `Enter a function body that returns a string. Available input parameters: mainData, restData, metaData or use array "arguments"`,
        filterStaticHint: `Enter a format string [field] [predicate] [value], for example, name == Cancel`,
        contextMenuJsonHint:
          '[{ name, isHeader, isDivider, command, commands: [{type, name}], additionalData, items: [child items of the same format] },...]',
      },
      placeholder: {
        titleJS: `return 'string'`,
        tooltipJS: 'return [...]',
      },
      bindTypeForm: 'Form',
      setDefault: 'Set Default',
      command: 'Command',
    },
    resourceForm: {
      step: 'Axis Y',
      columns: 'columns',
      actions: 'actions',
      addAction: 'Add Action',
      addColumn: 'Add Column',
      key: 'Axis Y Key',
      sortBindingType: 'Sort Binding',
      sort: 'Default Sort Data',
      sortTextHint: `Expected format: [ { "field":"name","order":"asc" } ]`,
      sortScriptHint: `Expected the function that returns the sorting criterion`,
      filter: 'Default Filter Data',
      hint: {
        filterStaticHint: `Enter a format string [field] [predicate] [value], for example, name == Cancel`,
        tooltipScriptHint:
          'Enter a function body that returns an array of objects. Also you can use mainData, restData, metaData.',
        tooltipJsonHint: 'Enter an array of objects.',
        contextMenuJsonHint:
          '[{ name, isHeader, isDivider, command, commands: [{type, name}], additionalData, items: [child items of the same format] },...]',
      },
      placeholder: {
        tooltipJS: 'return [...]',
      },
      bindTypeForm: 'Form',
      setDefault: 'Set Default',
      command: 'Command',
    },
    defaultSortForm: {
      step: 'Default Sort',
      sortAxis: 'Sort by Axis',
      field: 'Field',
      order: 'Order',
      sortObj: 'Sort Object',
    },
    common: {
      remove: 'REMOVE',
      add: 'ADD',
      apply: 'Apply',
    },
    columnForm: {
      code: 'Internal Id',
      title: 'Title',
      bindType: 'Binding',
      value: 'Value',
      valueType: 'Value Type',
      pinnedColumn: 'Pinned Column',
    },
    actionForm: {
      code: 'Internal Id',
      title: 'Title',
      eventCode: 'Event Code',
      actionCode: 'Action',
      objectFormCode: 'Object Form',
      columnTarget: 'Column Target',
      segmentTarget: 'Segment Target',
    },
    unitIntervalOptions: {
      minute: 'Minute',
      quarter: '15 Minutes',
      half: '30 Minutes',
      hour: 'Hour',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      year: 'Year',
    },
    actionConfigExamples: {
      title: 'Config Examples',
      subtitle: 'Click the config to copy it',
    },
  },
  tableWithFilters: {
    title: 'All',
  },
  dictionary: {
    searchPlaceholder: 'Search orders...',
  },
  viewsExplore: {
    searchPlaceholder: 'Search orders...',
    filters: {
      name: 'Models',
      defaultValue: 'All',
    },
  },
  formCreate: {
    titleJsValue: {
      label: 'Value',
      placeholder: 'Title value',
    },
    isJsTitle: {
      label: 'JS',
    },
    contextMenu: {
      placeholder: 'Select a Context Menu',
      label: 'Context Menu',
    },
  },
  viewsCreate: {
    datasource: 'Datasource',
    editModel: {
      views: 'Edit View',
      forms: 'Edit Form',
    },
    newModel: {
      views: 'New View',
      forms: 'New Form',
      id: {
        placeholder: 'Model id',
        label: 'Id',
      },
      code: {
        placeholder: 'Internal id',
        label: 'Internal Id',
      },
      title: {
        placeholder: 'Title',
        label: 'Title',
      },
      preFilter: {
        placeholder: 'Pre-filter',
        label: 'Pre-filter',
      },
      bindRow: {
        label: 'Bind Row to Object',
      },
      defaultSorting: {
        label: 'Use Default Sorting',
      },
    },
    parameters: {
      title: 'Parameters',
    },
    forms: {
      name: 'Fields',
      btnText: 'Add field',
      config: 'Config',
    },
    views: {
      name: 'Columns',
      btnText: 'Add column',
    },
    actions: {
      name: 'Actions',
      btnText: 'Add action',
    },
  },
  profile: {
    personalInfoTitle: 'Personal Information',
    roles: {
      globalTitle: 'Global Access',
      resourceTitle: 'Resource Access',
    },
    save: 'Save',
  },
  modal: {
    applyBtnText: 'Apply',
    saveBtnText: 'Save',
    closeBtnText: 'Close',
    cancelBtnText: 'Cancel',
    edit: {
      title: 'Edit',
    },
    cancelMenuChanges: {
      title: 'Canceling',
      text: 'Are you sure you want to canсel?',
    },
    delete: {
      title: 'Removing',
      warning: 'Are you sure you want to delete?',
      btn: 'Delete',
    },
    changeObjetAxisY: {
      title: 'confirm deletion',
      warning:
        'Selected object involved in settings of columns and bar links. If submit all columns and bars will be removed',
      btn: 'Delete',
    },
    changeObjetAxisX: {
      title: 'Confirm deletion',
      warning:
        'Selected object involved in settings of segments. If submit all segments will be removed',
      btn: 'Delete',
    },
    deleteBar: {
      title: 'Confirm deletion',
      warning: 'Are you sure you want to delete Bar {{index}}',
      btn: 'Delete',
    },
  },
  addDialog: {
    create: 'New',
    edit: 'Edit',
    parameters: 'Parameters',
  },
  viewOrFormCreate: {
    saveBtnText: 'Save',
    cancelBtnText: 'Close',
    saveConfigurationText: 'Save configuration',
    cancelConfigurationText: 'Cancel',
    nextStepText: 'Next',
    prevStepText: 'Previous',
    resetText: 'Reset',
    stepViewText: 'View Configuration',
    stepTabsText: 'Filter Tabs Configuration',
    stepSave: 'Save view',
    filtersStepTitle: 'Choose Tabs',
    sortingsStepTitle: 'Choose Sortings for the Table',
    tabsTitle: 'Created Filter Tabs',
    sortingsTableTitle: 'Created Sortings',
    tableConfigTitle: 'Table Configuration',
    mainColumnsTitle: 'Main Columns',
    actionColumnsTitle: 'Action Columns',
    tabsForm: {
      title: {
        placeholder: 'Tab title',
        label: 'Title',
      },
      filterValue: {
        placeholder: 'Filter value',
        label: 'Filter value',
      },
      filterColumns: {
        label: 'Filter Columns',
        placeholder: 'Select columns',
      },
      sortingDirection: {
        placeholder: 'Select a direction',
        label: 'Sorting Direction',
      },
      sortingColumns: {
        label: 'Sorting Column',
        placeholder: 'Select column',
      },
      addBtnText: 'Add',
      skipBtnText: 'Skip this step',
    },
    sortingsForm: {
      title: {
        label: 'Sorting Title',
        placeholder: 'Sorting Title',
      },
      code: {
        label: 'Sorting Internal Id',
        placeholder: 'Sorting internal id',
      },
      columns: {
        label: 'Sorting to Apply',
      },
      direction: {
        label: 'Sorting Direction',
      },
      defaultSorting: {
        label: 'Default Sorting',
      },
    },
  },
  configContextMenu: {
    add: 'Add Context Menu',
    hasObjects: 'You are going to delete the context menu associated with the objects:',
  },
  contextMenuCreate: {
    title: 'New/Edit Context Menu',
    tabs: {
      menuPoints: 'Menu Points',
      variables: 'Variables',
    },
    addMenuPoint: 'Add menu point',
    addMenuPointModal: {
      titleModal: 'New/Edit Context Menu Point',
      header: {
        label: 'Header',
        placeholder: 'Header',
      },
      divider: {
        label: 'Divider',
        placeholder: 'Divider',
      },
      group: {
        label: 'Group',
        placeholder: 'Group',
      },
      title: {
        label: 'Title',
        placeholder: 'Title',
        errors: {
          used: '{{name}} is already used',
        },
      },
      jsTitle: {
        label: 'js',
        placeholder: 'js',
      },
      titleJsValue: {
        label: 'Value',
        placeholder: 'Title Value',
      },
      menuPointType: {
        label: 'Menu Point Type',
        placeholder: 'Select a Menu Point Type',
      },
      objectType: {
        label: 'Object Type',
        placeholder: 'Select an Object Type',
      },
      value: {
        label: 'Value',
        placeholder: 'Select a Value',
      },
      conditionsDisabled: {
        label: 'Disabled When',
        placeholder: 'Disabled When',
      },
      conditionsHidden: {
        label: 'Hidden When',
        placeholder: 'Hidden When',
      },
    },
    addVariable: 'Add variable',
    form: {
      title: {
        placeholder: 'Title',
        label: 'Title',
      },
      object: {
        placeholder: 'Object',
        label: 'Object',
      },
      saveButton: 'Save',
      cancelButton: 'Cancel',
    },
  },
  menuSetting: {
    formForAdding: {
      item: {
        label: 'Add Menu Item or Group',
        placeholder: 'Name item or group',
        addItem: 'Add Item or Group',
      },
    },
    isEmpty: 'The Menu is Empty',
  },
  notifications: {
    success: 'Success',
    error: 'Something went wrong',
    leave: 'You have unsaved changes. Are you sure?',
    rowIsFull: 'There is no free space in this line',
    cannotFitView: 'You can add "list" to a new line',
    columnHidding:
      'Sorting column not found. Check the visibility setting of the column "{{columnName}}"',
    emptyFilter:
      'No values were found for the applied filter. Check the "Filter value" parameters in the tab "{{tabName}}"',
    changeContext:
      'You are going to change the Context. All open windows will be closed. Are you sure?',
  },
  form: {
    close: 'Cancel',
    save: 'Save',
    edit: 'Edit',
    empty: 'No rows',
    noForm: 'Form not found',
    deleteRow: 'Object not found',
    show: 'Show',
    hide: 'Hide',
    fieldObjectRequired: 'This field is required on object level',
  },
  field: 'Field',
  cell: {
    error: 'Error',
  },
  reactTable: {
    noRows: 'No rows',
  },
  filter: {
    buttonInfo: 'The filter is saved in the "Saved seacrh" section View',
    search: 'Search',
    clean: 'Clean',
    create: 'Search assistant',
    pinned: 'Pinned filters',
    saved: 'Saved search',
    chipsDesc: 'Search filters',
    editTitle: 'Edit the title of a saved search',
    edit: 'Edit',
    save: 'Save',
    delete: 'Delete',
    rules: {
      contain: 'Contain',
      notContain: 'Not contain',
      equal: 'Equal',
      notEqual: 'Not equal',
      startsWith: 'Starts with',
      endsWith: 'Ends with',
      empty: 'Empty',
      notEmpty: 'Not empty',
      more: 'More',
      less: 'Less',
      moreOrEqual: 'More or equal',
      lessOrEqual: 'Less or equal',
    },
    favorites: {
      button: 'Favorites',
      manage: 'Manage ...',
      save: 'Save',
      saveAs: 'Save as ...',
      dialog: {
        title: 'Filters',
        columnName: 'Name',
        rename: 'Rename',
        delete: 'Delete',
        ok: 'Ok',
        cancel: 'Cancel',
        close: 'Close',
      },
    },
    reset: 'Reset',
  },
  historyTable: {
    title: 'History',
  },
  searchAssistant: {
    title: 'Search Assistant',
    addBtnText: 'Add Search Filter',
    result: 'Results({{totalElements}})',
    defaultView: 'Default View',
    searchRule: 'Search rule',
    alreadyUsedDialog: {
      title: 'Save',
      cancel: 'Cancel',
      replace: 'Replace',
      info: 'The search "{{name}}"  is already saved in the system.',
      question: 'Do you want to replace this search?',
    },
    defaultSetting: {
      title: 'Default Setting',
      button: 'Default Setting',
      objectsLabel: 'OBJECTS',
      autocompleteLabel: 'DEFAULT FILTERS',
    },
    filtration: {
      object: {
        label: 'Object',
        placeholder: 'Select a Object Value',
      },
      view: {
        label: 'Search for',
        placeholder: 'Select a View',
      },
    },
    affects: 'Affects the order in the {{entity}}',
  },
  searchAssistantTable: {
    id: 'ID',
    iternalID: 'Iternal ID',
    title: 'Title',
    object: 'Object',
    value_params: 'Value Params',
    value: 'Value',
    field_type: 'Field Type',
    objectValue: 'Object Value',
  },
  sidebar: {
    links: {
      styleSettings: 'Style settings',
      contexts: 'Contexts',
      controlPanels: 'Control Panels',
      contextMenu: 'Context Menu',
      ganttChart: 'Gantt-Chart',
      uiConfigs: 'UI configs',
      views: 'Views',
      forms: 'Forms',
      listControls: 'List Controls',
      dropdownEntities: 'Dropdown Entities',
      dictionary: 'Dictionary',
      menuSetting: 'Menu Setting',
      searchAssistant: 'Search Assistant',
      search: 'Search Assistant',
      parameters: 'Parameters',
      variables: 'Variables',
      profile: {
        settings: 'Settings',
        edit: 'Edit Profile',
        help: 'Help',
      },
    },
  },
  viewColumnsTable: {
    id: 'Internal Id',
    title: 'Title',
    bindType: 'Binding',
    value: 'Value',
    valueType: 'Value Type',
    columnToSort: 'Field to Sort and Filter',
    pinnedColumn: 'Pinned Column',
  },
  viewActionsTable: {
    id: 'Internal Id',
    title: 'Title',
    eventCode: 'Event',
    actionCode: 'Action',
    params: 'Params',
    objectFormCode: 'Object Form Code',
    contextMenu: 'Context Menu',
    targetColumn: 'Target Column',
  },
  entityActionDialog: {
    contextMenu: {
      placeholder: 'Select a Context Menu',
      label: 'Action Value',
    },
    targetColumn: {
      placeholder: 'Select a Target Column',
      label: 'Target',
    },
  },
  configColumnsTable: {
    id: 'ID',
    code: 'Internal Id',
    title: 'Title',
    objectTitle: 'Object',
  },
  formColumnsTable: {
    code: 'Internal Id',
    title: 'Title',
    bindType: 'Binding',
    value: 'Value',
    valueType: 'Value Type',
  },
  tabsColumnTable: {
    title: 'Title',
    filterValue: 'Filter Value',
    sortingColumns: 'Sorting Column',
    sortingDirection: 'Sorting Direction',
  },
  sortingsColumnsTable: {
    title: 'Title',
    columns: 'Sorting Value',
    direction: 'Sorting Direction',
  },
  dictionaryTable: {
    id: 'ID',
    code: 'Internal Id',
    title: 'Title',
    categoryTitle: 'Category',
  },
  objectFieldChangesColumnsTable: {
    changeDate: 'Date',
    author: 'Owner',
    field: 'Field',
    newValue: 'New Value',
    oldValue: 'Old Value',
  },
  variableTable: {
    internalId: 'Internal Id',
    title: 'Title',
    variableType: 'Variable Type',
    valueType: 'Value Type',
  },
  fieldsConfig: {
    configuration: 'Configuration',
    fields: 'Fields',
    lists: 'Lists',
    elements: 'Elements',
    delete: 'Delete',
    deleteText: 'Are you sure you want to delete this element?',
    deleteBtnText: 'Delete',
    cancelDeleteBtnText: 'Cancel',
    placeholder: {
      emptyContainer: ' Create a New Tab',
      title: 'Enter a Title',
      text: 'Enter a Text',
      fieldsFilter: 'Enter the Field Title',
    },
    filters: {
      addButton: 'Add filter',
      dialog: {
        title: '{{type}} filter',
        labels: {
          title: 'Title',
          code: 'Iternal Id',
          filter: 'Filter',
        },
        placeholder: {
          title: 'Title',
          code: 'Iternal Id',
          filter: 'Enter a code filter',
        },
      },
    },
  },
  actionInputs: {
    code: {
      placeholder: 'Action internal id',
      label: 'Internal Id',
    },
    title: {
      placeholder: 'Action title',
      label: 'Title',
    },
    actionCode: {
      placeholder: 'Action type',
      label: 'Action type',
    },
    eventCode: {
      label: 'Event',
    },
    params: {
      placeholder: 'Json params',
      label: 'Params',
    },
    column: {
      label: 'Column',
      placeholder: 'Target column',
    },
    segment: {
      label: 'Segment',
      placeholder: 'Target segment',
    },
  },
  columnInputs: {
    errors: {
      alreadyUsed: 'is already used!',
    },
    value: {
      fieldPlaceholder: 'Field value',
      label: 'Value',
    },
    code: {
      placeholder: 'Internal id',
      label: 'Internal id',
    },
    title: {
      placeholder: 'Field title',
      label: 'Title',
    },
    bindType: {
      label: 'Binding',
    },
    columnToSort: {
      label: 'Field to Sort and Filter',
    },
    pinning: {
      label: 'Pinning',
    },
    pinnedColumn: {
      label: 'Pinned column',
    },
    useParameters: {
      label: 'Use Parameters',
    },
    quickSearch: {
      label: 'Quick search',
      disabledColumnRule: `This column is not supported by the rule "{{name}}"`,
      checkAllCheckbox: {
        label: 'Check all columns',
        booleanLabel: 'Check all boolean columns',
      },
    },
    enumDescription: {
      label: 'Description for enum',
    },
  },
  searchFilterForm: {
    formTitle: {
      new: 'New Filter',
      edit: 'Edit Filter',
    },
    alreadyUsedCode: 'is already used!',
    emptySearch: 'There are no saved searches',
    emptyCriterias: 'No search filters',
    dialog: {
      title: 'Save search filter',
      description: 'Please enter the title of the search filter:',
      label: 'Title',
    },
    id: {
      placeholder: 'Internal Id',
      label: 'Internal Id',
    },
    title: {
      placeholder: 'Field title',
      label: 'Title',
    },
    object: {
      placeholder: 'Select a object value',
      label: 'Object',
    },
    value: {
      placeholder: 'Select a value',
      label: 'Value',
      addButtonTitle: 'ADD VALUE',
    },
    fieldType: {
      placeholder: 'Select a field type',
      label: 'Field Type',
    },
    objectValue: {
      placeholder: 'Select a object value',
      label: 'Object Value',
    },
    pinning: {
      label: 'Pinning',
    },
    groupRelated: {
      label: 'Group related',
    },
    defaultPlaceholder: {
      label: 'Default',
    },
    placeholderValue: {
      placeholder: 'Add name',
      label: 'Placeholder',
    },
    placeholderStart: {
      placeholder: 'Add name',
      label: 'Placeholder',
    },
    placeholderEnd: {
      placeholder: 'Add name',
      label: 'Placeholder',
    },
  },
  groups: {
    variant: {
      view: {
        element: 'View',
        group: 'Group of views',
        error: {
          alreadyUsed: 'is already used!',
        },
      },
      listControl: {
        element: 'List Control',
        group: 'Group of list controls',
        error: {
          alreadyUsed: 'is already used!',
        },
      },
      forms: {
        element: 'Form',
        group: 'Group of forms',
      },
    },
    placeholder: {
      view: {
        groupName: 'Enter the name of the group',
        select: 'Select a view',
        selectType: 'Select element type',
      },
      listControl: {
        groupName: 'Enter the name of the group',
        select: 'Select a view',
        selectType: 'Select element type',
      },
      forms: {
        groupName: 'Enter the name of the group',
        select: 'Select a form',
        selectType: 'Select element type',
      },
    },
  },
  copying: {
    view: {
      dialogTitle: 'Copying view',
    },
    form: {
      dialogTitle: 'Copying form',
    },
    context: {
      dialogTitle: 'Copying context',
    },
    controlPanel: {
      dialogTitle: 'Copying control panel',
    },
    contextMenu: {
      dialogTitle: 'Copying context menu',
    },
    listControl: {
      dialogTitle: 'Copying list control',
    },
    dropdownEntity: {
      dialogTitle: 'Copying dropdown entity',
    },
    inputs: {
      code: {
        label: 'Internal Id',
        placeholder: 'Internal Id',
      },
      title: {
        label: 'Title',
        placeholder: 'Title',
      },
      objectCode: {
        label: 'Object',
      },
    },
  },
  parameters: {
    resetButton: 'Reset to default',
    resetModal: {
      title: 'Resetting',
      text: 'Are you sure you want to reset?',
      actionBtnText: 'Reset',
    },
    common: {
      title: 'Common Parameters',
      save: 'Save',
      numberOfWindows: 'Number of Windows',
      timeZone: 'Time Zone',
      pagination: 'Pagination',
      ganttChart: 'Gantt Chart',
    },
    visual: {
      example: 'example',
      save: 'Save',
      edit: 'Edit',
      cancel: 'Cancel',
      title: 'Visual Parameters',
      header: 'Views. Global Header Text Settings',
      background: 'Background',
      border: 'Border',
      columnHeader: 'Header',
      columnText: 'Text',
      alignOptions: {
        left: 'Left',
        center: 'Center',
        right: 'Right',
      },
      exampleText: 'Example',
    },
  },
  objectPicker: {
    openForm: 'Open form: {{formTitle}}',
  },
  configEntity: {
    listControl: {
      tabs: {
        all: 'All',
        groups: 'Groups',
      },
      columns: {
        title: 'Columns',
        addButton: 'Add Column',
      },
      actions: {
        title: 'Actions',
        addButton: 'Add Action',
      },
      order: {
        title: 'Order',
      },
      parameters: {
        title: 'Parameters',
      },
      addButton: 'Add List Control',
      search: 'Search',
      object: 'Object',
      editModel: 'Edit List Control',
      newModel: 'Add List Control',
    },
    dropdownEntity: {
      tabs: {
        all: 'All',
      },
      columns: {
        title: 'Columns',
        addButton: 'Add Column',
      },
      order: {
        title: 'Order',
      },
      parameters: {
        title: 'Parameters',
      },
      addButton: 'Add Dropdown Entity',
      search: 'Search',
      object: 'Object',
      editModel: 'Edit Dropdown Entity',
      newModel: 'Add Dropdown Entity',
    },
    view: {
      tabs: {
        all: 'All',
        groups: 'Groups',
      },
      columns: {
        title: 'Columns',
        addButton: 'Add Column',
      },
      actions: {
        title: 'Actions',
        addButton: 'Add Action',
      },
      order: {
        title: 'Order',
      },
      parameters: {
        title: 'Parameters',
      },
      addButton: 'Add View',
      search: 'Search',
      object: 'Object',
      editModel: 'Edit View',
      newModel: 'Add View',
      resetOrder: 'Reset order',
      fixedByAdmin: 'This column is fixed by the administrator.',
      allPinError: 'All visible columns cannot be pinned.',
    },
  },
  stringEdit: {
    buttons: {
      cancel: 'Cancel',
      save: 'Save',
    },
    title: 'Edit field {{field}}',
  },
  styleSettings: {
    addButton: {
      styleSetting: 'Add Style Setting',
    },
    title: {
      create: 'Add Style Setting',
      edit: 'Edit Style Setting',
      styleSetting: 'Title',
    },
    saveButton: 'save',
    cancelButton: 'cancel',
  },
  contexts: {
    addButton: {
      context: 'Add Context',
      view: 'Add View',
      ganttChart: 'Add Gantt Chart',
      controlPanel: 'Add Control Panel',
      variable: 'Add Variable',
    },
    saveButton: 'Save',
    cancelButton: 'Cancel',
    searchButton: 'Search',
    searchPlaceholder: 'Search',
    chooseContext: 'Choose context',
    continue: 'Continue',
    continueWithoutContext: 'Continue without context',
    placeholder: {
      selectContext: 'Select a context',
      emptyContext: 'No contexts',
      search: 'Search',
      selectView: 'Select a view',
      selectGantt: 'Select a gantt',
      selectControlPanel: 'Select a control panel',
      selectVariable: 'Select a variable',
    },
    title: {
      create: 'New Context',
      edit: 'Edit Context',
      context: 'Context title',
      view: 'View Title',
      ganttChart: 'Gantt Title',
      controlPanel: 'Control Panel Title',
      variable: 'Variable',
    },
    tabs: {
      views: 'Views',
      ganttCharts: 'Gantt Charts',
      controlPanels: 'Control Panels',
      variables: 'Variables',
      searchAssistant: 'Search Assistant',
    },
    tableColumns: {
      title: 'Title',
      object: 'Object',
      axisY: 'Axis Y Object',
      axisX: 'Axis X Object',
      variable: 'Variable',
      path: 'Path',
      value: 'Value',
      actions: {
        add: 'Add',
        delete: 'Delete',
        default: 'Default',
      },
    },
    addedViews: 'VIEWS ADDED TO CONTEXT',
    notAddedViews: 'VIEWS NOT ADDED TO CONTEXT',
    addedGanttCharts: 'GANTT CHARTS ADDED TO CONTEXT',
    notAddedGanttCharts: 'GANTT CHARTS NOT ADDED TO CONTEXT',
  },
  variables: {
    addButton: 'Add Variable',
    saveButton: 'Save',
    cancelButton: 'Cancel',
    searchPlaceholder: 'Search',
    title: {
      create: 'New variable',
      edit: 'Edit variable',
    },
    label: {
      internalId: 'Internal Id',
      title: 'Title',
      variableType: 'Variable Type',
      valueType: 'Value Type',
    },
  },
  addIcon: 'Add Icon',
  removeIcon: 'Remove Icon',
  iconPicker: {
    menu: 'ICON PICKER MENU',
    tab: {
      initial: 'INITIAL',
      common: 'COMMON',
      additional: 'ADDITIONAL',
    },
  },
  configByObject: {
    enumPostfix: 'ENUM',
    primaryKeyPostfix: 'PK',
  },
  configuredGantt: {
    incorrectDefFilters: 'Incorrect Default Config Filters',
    incorrectSubscriptionMessage: 'Incorrect Subscription Message',
  },
  executeCodeError: 'Ошибка выполенния кода: ',
  alreadyError: '{{value}} is already exist',
  contextMenu: {
    noOptions: 'No options',
  },
}
