import { Fragment, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import { FormInput, ModalWrapper } from '@microservices/wiskey-react-components'
import ClearIcon from '@mui/icons-material/Clear'
import { Box, Button, Divider, IconButton, SxProps } from '@mui/material'

import { CommandPickerController } from '@components/hookFormControllers/CommandPickerController'
import { FieldPickerController } from '@components/hookFormControllers/FieldPickerController'

import { GENERATOR_INPUT_TYPE } from '@constants'
import { FIELD_VALUE_TYPE } from '@constants'
import {
  AutocompleteOption,
  ENTITY_COMMAND_TYPE,
  EntityCommandDTO,
  GETObjectModel,
  ObjectCommandDTO,
} from '@types'

import { ConfigField, EmbeddedObject } from '@gantt/components/GanttCreateOrEdit'
import { getCommandsFormattedResponse, getDefaultCommand } from '@gantt/helpers'

type ModalRefPathPickerProps = {
  isDisabled: boolean
  isRequired?: boolean
  isEdit?: boolean
  isFlatOptions?: boolean
  hasClearInInput?: boolean
  hasField?: boolean
  hasCommands?: boolean
  name: string
  label?: string
  embeddedObjectPickerControllerName: string
  pickerName: string
  placeHolder?: string
  sx?: SxProps
  fieldName?: string
  commandsName?: string
  prefix?: string
  suffix?: string
  watchedObject?: AutocompleteOption<string> | null
  currentValue?: {
    pathStr: string
    pathArray: EmbeddedObject[]
    field: string
    commands: EntityCommandDTO[]
  }
  onChange?: () => void
  onSave?: (value: ConfigField) => void
  onRemove?: () => void
}

//todo почему не в src/components/ ?
export const ModalRefPathPicker = ({
  isDisabled,
  isEdit = false,
  isFlatOptions,
  isRequired = false,
  hasField = false,
  hasCommands = false,
  hasClearInInput = false,
  name,
  label,
  embeddedObjectPickerControllerName,
  pickerName,
  placeHolder,
  sx,
  fieldName,
  commandsName,
  prefix = '',
  suffix = '',
  watchedObject,
  currentValue,
  onChange,
  onSave,
  onRemove,
}: ModalRefPathPickerProps) => {
  const methods = useFormContext()
  const { watch, setValue, trigger, getValues } = methods
  const [show, setShow] = useState(false)
  const { t } = useTranslation()
  const [objectCode, setObjectCode] = useState('') //todo type
  const watchedRefPath = watch(name)

  const handleClear = () => {
    setValue(name, '', { shouldDirty: true })
    fieldName && setValue(fieldName, '', { shouldDirty: true })
    setValue(
      embeddedObjectPickerControllerName,
      [{ objectCode: watchedObject?.id, refValueType: FIELD_VALUE_TYPE.OBJ_PK_LINK, field: null }],
      { shouldDirty: true }
    )
    onRemove?.()
  }

  const getRefPath = (embeddedObjects: EmbeddedObject[], name: string) => {
    if (embeddedObjects) {
      const sum = embeddedObjects
        .map(obj => obj.field)
        .filter(item => item)
        .join('.')
      setValue(name, sum)

      return sum
    }
  }

  const objList = watch(embeddedObjectPickerControllerName) as EmbeddedObject[]

  const getFieldName = (fieldName: string, fieldValue: string, prefix?: string) => {
    const fieldPlace = [prefix, fieldValue, suffix].filter(s => !!s).join('_')

    return setValue(`${fieldName}`, fieldPlace)
  }

  const fieldVal = useMemo(() => {
    return objList
      ?.map(obj => obj.field)
      .filter(item => item)
      .join('')
  }, [objList])

  const handleSave = () => {
    trigger(embeddedObjectPickerControllerName).then(value => {
      if (value) {
        getRefPath(objList, name)

        if (fieldName && hasField) {
          getFieldName(fieldName, fieldVal, prefix)
        }

        setShow(false)
        onSave?.(getValues(pickerName))
      }
      onChange?.()
    })
  }

  const handleOpenModal = () => {
    setShow(true)
  }

  const handleCloseModal = () => {
    setValue(name, currentValue?.pathStr)
    setValue(embeddedObjectPickerControllerName, currentValue?.pathArray)
    fieldName && setValue(fieldName, currentValue?.field)
    hasCommands && commandsName && setValue(commandsName, currentValue?.commands)

    setShow(false)
  }

  const handleOnChange = (object: GETObjectModel & { code: string }) => {
    if (hasCommands) {
      const defaultCommand: ObjectCommandDTO | null = getDefaultCommand(
        object?.commands || [],
        'def_update'
      )

      const newDefaultCommand = (defaultCommand && [
        {
          name: { id: defaultCommand.code, label: defaultCommand.code },
          type: {
            id: ENTITY_COMMAND_TYPE.UPDATE,
            label: ENTITY_COMMAND_TYPE.UPDATE,
          },
        },
      ]) || [{ type: null, name: null }]

      commandsName && setValue(commandsName, newDefaultCommand)
    }
    setObjectCode(object?.code)
  }

  const handleOnInit = (object: GETObjectModel & { code: string }) => {
    if (hasCommands) {
      const defaultCommand: ObjectCommandDTO | null = getDefaultCommand(
        object?.commands || [],
        'def_update'
      )

      const newDefaultCommand = defaultCommand
        ? [
            {
              name: { id: defaultCommand.code, label: defaultCommand.code },
              type: {
                id: defaultCommand.code,
                label: ENTITY_COMMAND_TYPE.UPDATE,
              },
            },
          ]
        : [{ type: null, name: null }]

      commandsName && isEmpty(watch(commandsName)) && setValue(commandsName, newDefaultCommand)
    }
    setObjectCode(object?.code)
  }

  useEffect(() => {
    if (show && hasCommands && commandsName) {
      setValue(commandsName, getCommandsFormattedResponse(getValues(commandsName)))
    }
  }, [show])

  return (
    <Fragment key={String(name)}>
      <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'} sx={sx}>
        <Box flex={1}>
          <FormInput
            inputType={GENERATOR_INPUT_TYPE.INPUT}
            label={label}
            name={name}
            placeholder={placeHolder ?? t('placeholder.refPath')}
            readOnly={true}
            rules={{
              required: isRequired,
            }}
            textFieldInputProps={{
              ...(hasClearInInput && {
                endAdornment: (
                  <IconButton
                    disabled={!watchedRefPath}
                    size={'small'}
                    sx={{ width: 36.5, py: '10px' }}
                    onClick={handleClear}
                  >
                    <ClearIcon fontSize={'small'} />
                  </IconButton>
                ),
              }),
            }}
          />
        </Box>
        <Box display={'flex'} flex={0.1} mb={1}>
          <Button
            disabled={isDisabled}
            variant='contained'
            sx={{
              margin: '0 15px',
            }}
            onClick={handleOpenModal}
          >
            {watchedRefPath ? t('ganttCreate.refEdit') : t('ganttCreate.refPick')}
          </Button>
          {!hasClearInInput && (
            <IconButton size={'small'} sx={{ width: 36.5 }} onClick={handleClear}>
              <ClearIcon fontSize={'small'} />
            </IconButton>
          )}
        </Box>
        {hasField && fieldName && (
          <Box hidden flex={0.4}>
            <FormInput
              inputType={GENERATOR_INPUT_TYPE.INPUT}
              name={fieldName}
              placeholder={placeHolder ?? t('placeholder.refPath')}
              readOnly={true}
              sx={{ margin: 0 }}
            />
          </Box>
        )}
      </Box>
      {show && (
        <ModalWrapper
          btnText={t('ganttCreate.common.apply')}
          isShow={show}
          onClose={handleCloseModal}
          onSave={handleSave}
        >
          <Box display={'flex'} justifyContent={'space-between'} mt={'15px'}>
            <Box width={'20%'}>{t('ganttCreate.modalPathPickerObjectsColumn')}</Box>
            <Divider flexItem orientation='vertical' />
            <Box width={'70%'}>{t('ganttCreate.modalPathPickerFieldsColumn')}</Box>
          </Box>
          <Box py={'15px'}>
            <FieldPickerController
              isEdit={isEdit}
              isFlatOptions={isFlatOptions}
              name={embeddedObjectPickerControllerName}
              watchedObject={watchedObject}
              onChange={handleOnChange}
              onInit={handleOnInit}
            />
          </Box>
          {commandsName && hasCommands && (
            <Box>
              {objectCode && (
                <CommandPickerController
                  commandName={'name'}
                  hasAddButton={false}
                  name={commandsName}
                  objectCode={objectCode}
                />
              )}
            </Box>
          )}
        </ModalWrapper>
      )}
    </Fragment>
  )
}
