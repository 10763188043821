import { ViewRow } from 'src/types'
import { BIND_TYPE } from '@constants'
import { isObjectValueType } from '@helpers'

export const getColumnFieldName = (column: ViewRow) => {
  return column.code
}

export const getColumnFieldNameForSort = (column: ViewRow) => {
  // Колонки с js/jsx value
  if (column.bindType !== BIND_TYPE.FIELD) {
    return column.columnToSort
  }
  // Колонки, имеющие связь с другим объектом
  if (isObjectValueType(column.valueType) && column.objectValue) {
    return `${column.value}.${column.objectValue}`
  }
  return column.columnToSort || column.value
}
