import { useContext, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import i18next from 'i18next'
import { FormInputsType } from '@microservices/wiskey-react-components/dist/types'

import { ACTION_CODE, EVENT_CODE_GANTT, GENERATOR_INPUT_TYPE, REGEX } from '@constants'
import { SelectOption } from '@types'

import {
  GANTT_BIND_TYPE_LIST,
  GanttActionType,
  PageContext,
} from '@gantt/components/GanttCreateOrEdit'
import {
  getAvailableColumnTargets,
  getAvailableYEvents,
  getBindTypeOptionsByStrings,
  getOptionByStr,
  isCellTarget,
} from '@gantt/helpers'

type useInputsParams = {
  actionTypeOptions: SelectOption[]
  eventOptions: SelectOption[]
  watchJson?: boolean
  watchEventCode?: string
  targetOptions: SelectOption[]
  methods: UseFormReturn<GanttActionType, any, undefined>
  watchActionCode?: ACTION_CODE
}

const contextMenuEvents = getBindTypeOptionsByStrings([
  EVENT_CODE_GANTT.ON_HEADER_RIGHT_CLICK,
  EVENT_CODE_GANTT.ON_ROW_RIGHT_CLICK,
])

const commandEvents = getBindTypeOptionsByStrings([
  EVENT_CODE_GANTT.ON_ROW_CLICK,
  EVENT_CODE_GANTT.ON_CELL_CLICK,
])

const tooltipEvents = getBindTypeOptionsByStrings([
  EVENT_CODE_GANTT.ON_TOOLTIP_ROW,
  EVENT_CODE_GANTT.ON_TOOLTIP_HEADER,
])

export const useInputs = ({
  actionTypeOptions,
  eventOptions,
  watchEventCode,
  targetOptions,
  methods,
  watchActionCode,
}: useInputsParams) => {
  const { modalType, currentActionResourceList, currentRow } = useContext(PageContext)
  const { setValue, resetField } = methods

  const handleChangeActionCode = (value: string | number) => {
    const getBind = () => {
      switch (value) {
        case ACTION_CODE.OPEN_CONTEXT_MENU:
          return GANTT_BIND_TYPE_LIST.JSON
        case ACTION_CODE.EXECUTE_COMMAND:
          return GANTT_BIND_TYPE_LIST.COMMANDS
        default:
          return null
      }
    }

    if (value === ACTION_CODE.EXECUTE_COMMAND) {
      setValue('actionField.commands', [{ type: null, name: null }])
    }

    const bind = getBind()

    resetField('eventCode')
    setValue('actionField.bindType', bind)
  }

  const eventCodeOptions = useMemo(() => {
    if (watchActionCode === ACTION_CODE.OPEN_CONTEXT_MENU) {
      return getAvailableYEvents({
        actionsList: currentActionResourceList,
        actionEventsList: contextMenuEvents,
        currentRow,
      })
    }

    if (watchActionCode === ACTION_CODE.OPEN_TOOLTIP) {
      return getAvailableYEvents({
        actionsList: currentActionResourceList,
        actionEventsList: tooltipEvents,
        currentRow,
      })
    }

    if (watchActionCode === ACTION_CODE.EXECUTE_COMMAND) {
      return getAvailableYEvents({
        actionsList: currentActionResourceList,
        actionEventsList: commandEvents,
        currentRow,
      })
    }

    if (watchActionCode === ACTION_CODE.OPEN_FORM_MODEL) {
      return getAvailableYEvents({
        actionsList: currentActionResourceList,
        actionEventsList: eventOptions,
        currentRow,
      })
    }

    return getAvailableYEvents({
      actionsList: currentActionResourceList,
      actionEventsList: eventOptions,
      currentRow,
    })
  }, [watchActionCode, eventOptions])

  const actionCodeOptions = useMemo(
    () => [
      ...actionTypeOptions,
      getOptionByStr(ACTION_CODE.OPEN_CONTEXT_MENU),
      getOptionByStr(ACTION_CODE.OPEN_TOOLTIP),
      getOptionByStr(ACTION_CODE.EXECUTE_COMMAND),
    ],
    [actionTypeOptions]
  )

  const actionInputs: FormInputsType[] = [
    {
      name: 'code',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: i18next.t('actionInputs.code.placeholder'),
      replacePattern: REGEX.MODEL_CODE_REPLACE_PATTERN,
      label: i18next.t('actionInputs.code.label'),
      disabled: modalType === 'edit',
      rules: { required: true, validate: value => value.trim().length !== 0 },
    },
    {
      name: 'title',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: i18next.t('actionInputs.title.placeholder'),
      label: i18next.t('actionInputs.title.label'),
      rules: { required: true, validate: value => value.trim().length !== 0 },
    },
    {
      name: 'actionCode',
      inputType: GENERATOR_INPUT_TYPE.SELECT,
      label: i18next.t('actionInputs.actionCode.label'),
      placeholder: i18next.t('placeholder.actionType'),
      selectOptions: actionCodeOptions,
      rules: { required: true, validate: value => value !== i18next.t('placeholder.actionType') },
      onChangeSelect: handleChangeActionCode,
    },
    {
      name: 'eventCode',
      inputType: GENERATOR_INPUT_TYPE.SELECT,
      label: i18next.t('actionInputs.eventCode.label'),
      placeholder: i18next.t('placeholder.event'),
      selectOptions: eventCodeOptions,
      rules: { required: true, validate: value => value !== i18next.t('placeholder.event') },
    },
    {
      name: 'targetRow',
      inputs: isCellTarget(watchEventCode)
        ? [
            {
              name: 'target',
              inputType: GENERATOR_INPUT_TYPE.SELECT,
              label: i18next.t('actionInputs.column.label'),
              placeholder: i18next.t('actionInputs.column.placeholder'),
              selectOptions: getAvailableColumnTargets({
                columnTargets: targetOptions,
                actionsList: currentActionResourceList,
                currentRow,
              }),
              rules: { required: true },
            },
          ]
        : [],
    },
  ]

  return actionInputs
}
