import { useEffect, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { FORM_TYPE } from '@constants'

import { getDefaultValueByType } from '@components/DisplayForm/helpers'
import { useCreateObjectDataRecordWithFixedKey } from '@components/DisplayForm/hooks/useCreateObjectDataRecordWithFixedKey'
import { useFetchObjectDataWithFixedKey } from '@components/DisplayForm/hooks/useFetchObjectDataWithFixedKey'
import { useUpdateObjectDataRecordWithFixedKey } from '@components/DisplayForm/hooks/useUpdateObjectDataRecordWithFixedKey'

import { useFetchObjectByCodeQuery } from '@redux/api'

import { ObjectDataRecord, Row } from '../../../types'

type UseFormResetLogicParams = {
  methods: UseFormReturn<Row>
  newObjectCode: string | undefined
  objectCode: string
  // row: ObjectDataRecord | undefined
  type: FORM_TYPE
  globalId: string
}

export const useFormResetLogic = ({
  methods: formMethods,
  newObjectCode,
  objectCode,
  type,
  globalId,
}: UseFormResetLogicParams) => {
  const { reset } = formMethods

  const [, { data: fetchedRow }] = useFetchObjectDataWithFixedKey(globalId)

  const { data: object } = useFetchObjectByCodeQuery(newObjectCode || objectCode, {
    skip: !objectCode,
  })

  const [, { isLoading: isLoadingUpdateRow }] = useUpdateObjectDataRecordWithFixedKey(globalId)

  const [, { isLoading: isLoadingCreateRow }] = useCreateObjectDataRecordWithFixedKey(globalId)

  const objectFields = useMemo(() => object?.fields || [], [object])

  const row = useMemo(() => (type === FORM_TYPE.CREATE ? {} : fetchedRow), [type, fetchedRow])

  const defaultValues: ObjectDataRecord = useMemo(
    () =>
      Object.fromEntries(
        objectFields.map(({ name: valueName, valueType }) => {
          return getDefaultValueByType(valueType, valueName, row?.[valueName])
        })
      ),
    [objectFields, row]
  )

  useEffect(() => {
    if (type === FORM_TYPE.CREATE) {
      reset(defaultValues)
    }
    if (row) {
      reset({ ...defaultValues, ...row })
      return
    }
  }, [row, defaultValues, type])

  useEffect(() => {
    if ((!isLoadingUpdateRow || !isLoadingCreateRow) && type !== FORM_TYPE.CREATE) {
      reset({ ...defaultValues, ...row })
    }
  }, [row, defaultValues, globalId])

  return { defaultValues }
}
