import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'

import { BindTypeInputs } from '@components/BindTypeInputs'
import { INPUT_TYPE } from '@components/BindTypeInputs/ValueInputFactory'

import { GENERATOR_INPUT_TYPE } from '@constants'
import { AutocompleteOption } from '@types'

import { BtnCopyConfigExample } from '@gantt/components/ConfigExamples'
import {
  BACKGROUND_BINDING_TYPE,
  BIND_TYPE_INPUTS_FORM_TYPE,
  PageContext,
  SegmentFieldType,
  TITLE_BINDING_TYPE,
  TOOLTIP_BINDING_TYPE,
} from '@gantt/components/GanttCreateOrEdit'
import { metaDataValues } from '@gantt/constants'
import { checkValidGanttJS, getBindTypeOptionsByEnum } from '@gantt/helpers'
import { GANTT_SCRIPT_RETURN_TYPE } from '@gantt/types'

type BaseFormFieldsProps = {
  disabled: boolean
  isShowScriptDialog?: boolean
  isShownCornersInput?: boolean
  mainPath?: string
  watchedObject?: AutocompleteOption<string> | null
  toggleOpenScriptValueDialog: () => void
  currentBarKey?: string
  keyValue?: string
  variablesForJS?: string[]
}

const colorHints = {
  js: i18next.t('ganttCreate.timelineForm.hint.backgroundColorScriptHint'),
  static: i18next.t('ganttCreate.timelineForm.hint.backgroundColorStaticHint'),
}

const tooltipHints = {
  js: i18next.t('ganttCreate.timelineForm.hint.tooltipScriptHint'),
}

const tooltipPlaceholders = {
  js: i18next.t('ganttCreate.timelineForm.placeholder.tooltipJS'),
}

const titleHints = {
  js: i18next.t('ganttCreate.timelineForm.hint.titleScriptHint'),
}

const titlePlaceholders = {
  js: i18next.t('ganttCreate.timelineForm.placeholder.titleJS'),
}

export const BaseFormFields = ({
  disabled,
  isShowScriptDialog = false,
  isShownCornersInput = false,
  mainPath,
  watchedObject,
  toggleOpenScriptValueDialog,
  keyValue,
  currentBarKey,
  variablesForJS,
}: BaseFormFieldsProps) => {
  const { mainDataAvailableVariables } = useContext(PageContext)
  const { t } = useTranslation()

  const getPath = (path: string) => {
    if (mainPath) {
      return `${mainPath}.${path}`
    }

    return `${path}`
  }

  const getPrefix = (type: SegmentFieldType) => {
    return [currentBarKey, keyValue, type].filter(s => !!s).join('_')
  }

  const funValidator = (value: string, returnValueType: GANTT_SCRIPT_RETURN_TYPE) =>
    checkValidGanttJS(
      value,
      {
        mainData: variablesForJS || mainDataAvailableVariables,
        metaData: metaDataValues,
      },
      returnValueType
    )

  const funValidatorString = useCallback((value: string) => {
    return funValidator(value, GANTT_SCRIPT_RETURN_TYPE.STRING)
  }, [])

  const funValidatorArrayOfObjects = useCallback((value: string) => {
    return funValidator(value, GANTT_SCRIPT_RETURN_TYPE.ARRAY_OF_OBJECTS)
  }, [])

  return (
    <>
      <BindTypeInputs
        fieldsetMarkup
        bindTypeOptions={getBindTypeOptionsByEnum(TITLE_BINDING_TYPE)}
        containerName={getPath('title')}
        fieldsetTitle={t('ganttCreate.timelineForm.generalTitle')}
        formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
        hintDict={titleHints}
        isDisabled={disabled}
        placeholderDict={titlePlaceholders}
        prefix={getPrefix(SegmentFieldType.TITLE)}
        validator={funValidatorString}
        watchedObject={watchedObject}
      />
      <BindTypeInputs
        fieldsetMarkup
        bindTypeOptions={getBindTypeOptionsByEnum(TOOLTIP_BINDING_TYPE)}
        containerName={getPath('tooltip')}
        fieldsetTitle={t('ganttCreate.timelinesObjectTooltips')}
        formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
        hintDict={tooltipHints}
        isDisabled={disabled}
        placeholderDict={tooltipPlaceholders}
        prefix={getPrefix(SegmentFieldType.TOOLTIP)}
        validator={funValidatorArrayOfObjects}
        watchedObject={watchedObject}
      />
      <Box display='flex' mb={1}>
        <Box pr={1}>Examples of js/json config:</Box>
        <BtnCopyConfigExample name={'Simple tooltip'} />
        <BtnCopyConfigExample name={'Group tooltip'} />
      </Box>
      <BindTypeInputs
        fieldsetMarkup
        bindTypeOptions={getBindTypeOptionsByEnum(BACKGROUND_BINDING_TYPE)}
        containerName={getPath('background')}
        fieldsetTitle={t('ganttCreate.timelineForm.background')}
        formType={BIND_TYPE_INPUTS_FORM_TYPE.TIMELINE}
        hintDict={colorHints}
        inputType={INPUT_TYPE.COLOR}
        validator={funValidatorString}
        watchedObject={watchedObject}
      />
      {isShownCornersInput && (
        <FormInput
          inputType={GENERATOR_INPUT_TYPE.CHECKBOX}
          label={t('ganttCreate.timelineForm.hasCorners')}
          labelPlacement={'end'}
          name={getPath('hasCorners')}
        />
      )}
    </>
  )
}
