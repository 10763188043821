import { ReactNode, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import i18next from 'i18next'
import i18n from 'i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { FormHelperText } from '@mui/material'

import { CommandPickerController } from '@components/hookFormControllers/CommandPickerController'
import { PickerFieldArrayController } from '@components/hookFormControllers/PickerFieldArrayController'
import { ScriptValueEditor } from '@components/ScriptValueDialog'
import { ColorSettings } from '@components/TextSettings/components/ColorSettings'

import { ErrorValidJSType } from '@helpers'
import { GENERATOR_INPUT_TYPE } from '@constants'

import { ModalRefPathPicker } from '@gantt/components/GanttCreateOrEdit/components/ModalRefPathPicker'
import {
  AutocompleteOption,
  BIND_TYPE_INPUTS_FORM_TYPE,
  ConfigField,
} from '@gantt/components/GanttCreateOrEdit/types'
import { getCommandsFormattedRequest, getKeyByBindType } from '@gantt/helpers'

import 'prismjs/components/prism-json'

export enum VALUES_WITH_BINDING_TYPE {
  FIELD = 'field',
  JS = 'js',
  STATIC = 'static',
  FIELD_ARRAY = 'field_array',
  JSON = 'json',
  CUSTOM = 'custom',
  COMMANDS = 'commands',
  FORM = 'form',
}

type ValueInputFactoryProps = {
  hasCommands?: boolean
  getPath: (key: VALUES_WITH_BINDING_TYPE | string) => string
  watchBinding: string
  watchedObject?: AutocompleteOption<string> | null
  formType: BIND_TYPE_INPUTS_FORM_TYPE
  hint?: string
  inputType?: InputType
  language: string
  valueInputLabel?: string
  isDisabled?: boolean
  prefix?: string
  children?: ReactNode
  placeholder?: string
  noValidate?: boolean
  validator?: (value: string) => ErrorValidJSType | undefined
  options?: (AutocompleteOption<string | number> & { default?: boolean })[]
}

export type InputType = GENERATOR_INPUT_TYPE | INPUT_TYPE

export enum INPUT_TYPE {
  COLOR = 'color',
}

export const ValueInputFactory = ({
  hasCommands,
  getPath,
  watchBinding,
  watchedObject,
  formType = BIND_TYPE_INPUTS_FORM_TYPE.RESOURCE,
  hint,
  inputType = GENERATOR_INPUT_TYPE.INPUT,
  language = 'js',
  valueInputLabel = i18n.t('label.value'),
  isDisabled = false,
  prefix,
  children,
  placeholder,
  noValidate,
  validator,
  options,
}: ValueInputFactoryProps) => {
  const { setValue, watch } = useFormContext()
  const watchValue = watch(getPath(getKeyByBindType(watchBinding)))
  const [initialValue, setInitialValue] = useState<ConfigField>()

  // ModalRefPathPicker restore value on cancel
  useEffect(() => {
    if (!initialValue?.field) {
      const clonedValue = structuredClone(watchValue)
      setInitialValue(clonedValue)
    }

    return () => setInitialValue(undefined)
  }, [watchValue?.field])

  const handleChange = (key: VALUES_WITH_BINDING_TYPE, value: string) => {
    setValue(getPath(key), value, { shouldDirty: true })
  }

  return (
    <>
      {watchBinding === VALUES_WITH_BINDING_TYPE.FIELD && (
        <ModalRefPathPicker
          hasField
          commandsName={getPath(`field.commands`)}
          currentValue={initialValue}
          embeddedObjectPickerControllerName={getPath(`field.pathArray`)}
          fieldName={getPath(`field.field`)}
          hasCommands={hasCommands}
          isDisabled={isDisabled}
          label={valueInputLabel}
          name={getPath(`field.pathStr`)}
          pickerName={getPath('field')}
          prefix={prefix}
          watchedObject={watchedObject}
          onSave={value => {
            hasCommands &&
              setValue(getPath(`field.commands`), getCommandsFormattedRequest(value.commands))
          }}
        />
      )}
      {watchBinding === VALUES_WITH_BINDING_TYPE.STATIC && inputType !== INPUT_TYPE.COLOR && (
        <>
          <FormInput
            inputType={inputType}
            label={valueInputLabel}
            name={getPath(VALUES_WITH_BINDING_TYPE.STATIC)}
            placeholder={placeholder}
          />
          {hint && <FormHelperText sx={{ pl: 15, ml: 0 }}>{hint}</FormHelperText>}
        </>
      )}
      {watchBinding === VALUES_WITH_BINDING_TYPE.STATIC && inputType === INPUT_TYPE.COLOR && (
        <>
          <ColorSettings
            clearableColor
            isEdit
            color={watchValue}
            label={valueInputLabel ?? i18n.t('label.value')}
            labelMaxWidth={120}
            labelPlacement={'left'}
            onChangeColor={value => handleChange(VALUES_WITH_BINDING_TYPE.STATIC, value)}
          />
          {hint && <FormHelperText sx={{ pl: 15, ml: 0 }}>{hint}</FormHelperText>}
        </>
      )}
      {watchBinding === VALUES_WITH_BINDING_TYPE.JS && (
        <ScriptValueEditor
          hint={hint}
          label={valueInputLabel}
          language={'js'}
          noValidate={noValidate}
          placeholder={placeholder}
          validator={validator}
          value={watchValue}
          onChange={value => handleChange(VALUES_WITH_BINDING_TYPE.JS, value)}
        />
      )}
      {watchBinding === VALUES_WITH_BINDING_TYPE.FIELD_ARRAY && (
        <PickerFieldArrayController
          disabled={isDisabled}
          isFlatOptions={false}
          label={valueInputLabel ?? i18next.t('label.value')}
          name={getPath(VALUES_WITH_BINDING_TYPE.FIELD_ARRAY)}
          prefix={prefix}
          watchedObject={watchedObject}
        />
      )}
      {watchBinding === VALUES_WITH_BINDING_TYPE.JSON && (
        <ScriptValueEditor
          hint={hint}
          label={valueInputLabel}
          language={'json'}
          placeholder={placeholder}
          value={watchValue}
          onChange={value => handleChange(VALUES_WITH_BINDING_TYPE.JSON, value)}
        />
      )}
      {watchBinding === VALUES_WITH_BINDING_TYPE.COMMANDS && (
        <CommandPickerController
          commandName={'name'}
          hasAddButton={false}
          name={getPath(VALUES_WITH_BINDING_TYPE.COMMANDS)}
          objectCode={watchedObject?.id || ''}
        />
      )}
      {watchBinding === VALUES_WITH_BINDING_TYPE.FORM && (
        <FormInput
          autocompleteOptions={options}
          inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
          label={valueInputLabel}
          name={getPath(VALUES_WITH_BINDING_TYPE.FORM)}
          placeholder={placeholder}
          rules={{ required: true }}
        />
      )}
      {watchBinding === VALUES_WITH_BINDING_TYPE.CUSTOM && !!children && children}
    </>
  )
}
