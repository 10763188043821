import { SortType } from '@hooks'
import { ObjectDataRecord } from '@types'

export const compareObjectDataRecords = (
  obj1: ObjectDataRecord,
  obj2: ObjectDataRecord,
  sort: SortType[]
): number => {
  for (const config of sort) {
    const sort = config.sort
    const [fieldRef, nestedField] = (config.sortKey || config.field).split('.')

    const obj1Field = nestedField ? obj1[`${fieldRef}_enriched`][nestedField] : obj1[fieldRef]
    const obj2Field = nestedField ? obj2[`${fieldRef}_enriched`][nestedField] : obj2[fieldRef]

    if (obj1Field !== obj2Field) {
      if (sort === 'asc') {
        return obj1Field < obj2Field ? -1 : 1
      } else {
        return obj1Field > obj2Field ? -1 : 1
      }
    }
  }
  return 0
}
