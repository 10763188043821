import { useCallback, useMemo, useState } from 'react'
import { ErrorValidJSType } from '@helpers'
import { FormLabel } from '@microservices/wiskey-react-components'
import { Box, FormHelperText, useTheme } from '@mui/material'

import { EditorBase } from './EditorBase'

type ScriptValueEditorProps = {
  value: string
  onChange: (value: string) => void
  label?: string
  language?: 'js' | 'json'
  hint?: string
  validator?: (value: string) => ErrorValidJSType | undefined
  placeholder?: string
  noValidate?: boolean
}

export const ScriptValueEditor = ({
  value,
  onChange,
  label,
  language = 'js',
  hint,
  validator,
  placeholder,
  noValidate = false,
}: ScriptValueEditorProps) => {
  const theme = useTheme()
  const [invalid, setInvalid] = useState(false)

  const getStyle = useMemo(() => {
    return {
      width: '100%',
      border: invalid ? theme.palette.error.light : theme.palette.border.field,
      transition: 'all .5s ease-in-out',
      flexGrow: 1,
    }
  }, [theme, invalid])

  const handleSetInvalid = useCallback((state: boolean) => {
    setInvalid(state)
  }, [])

  return (
    <>
      <Box display={'flex'}>
        <FormLabel
          label={label}
          name={''}
          control={
            <Box flexGrow={1}>
              <EditorBase
                invalid={invalid}
                language={language}
                noValidate={noValidate}
                placeholder={placeholder}
                style={getStyle}
                validator={validator}
                value={value}
                onChange={onChange}
                onInvalid={handleSetInvalid}
              />
              {hint && <FormHelperText sx={{ pt: 0.5, pl: 0, ml: 0 }}>{hint}</FormHelperText>}
            </Box>
          }
        />
      </Box>
    </>
  )
}
