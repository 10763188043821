import { GANTT_BIND_TYPE_LIST, SectionType } from '@gantt/components/GanttCreateOrEdit'

const fieldDefaultWithCommands = {
  field: '',
  pathStr: '',
  pathArray: [],
  commands: [{ type: null, name: null }],
}

const fieldDefault = {
  field: '',
  pathStr: '',
  pathArray: [],
}

export const getGanttSegmentDefaultValue = (): SectionType => {
  return {
    id: '',
    title: {
      bindType: GANTT_BIND_TYPE_LIST.STATIC,
      static: '',
    },
    tooltip: {
      bindType: GANTT_BIND_TYPE_LIST.FIELD_ARRAY,
      field_array: [structuredClone(fieldDefault)],
    },
    datetimeStart: {
      bindType: GANTT_BIND_TYPE_LIST.FIELD,
      field: structuredClone(fieldDefaultWithCommands),
    },
    datetimeEnd: {
      bindType: GANTT_BIND_TYPE_LIST.FIELD,
      field: structuredClone(fieldDefaultWithCommands),
    },
    duration: {
      bindType: GANTT_BIND_TYPE_LIST.FIELD,
      field: structuredClone(fieldDefault),
    },
    link: {
      axisY: {
        bindType: GANTT_BIND_TYPE_LIST.FIELD,
        field: structuredClone(fieldDefaultWithCommands),
      },
      axisX: {
        bindType: GANTT_BIND_TYPE_LIST.FIELD,
        field: structuredClone(fieldDefaultWithCommands),
      },
      resourceKeyField: 'sectionResource_',
      resourceLinkField: 'sectionLink_',
      systemName: '',
    },
    background: {
      bindType: GANTT_BIND_TYPE_LIST.STATIC,
      static: '',
    },
    labels: {
      leftTopTitle: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      bottomTitle: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      leftBottomTitle: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      rightBottomTitle: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      rightTopTitle: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      topTitle: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
    },
  }
}
